import { Controller } from 'stimulus';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = []

  connect() {
    if ($('.navbar-cookoon-new-with-flash-home')) {
      ScrollTrigger.create({
        start: 'top -80',
        end: 'bottom',
        toggleClass: {
          className: 'navbar-cookoon-new-with-flash-home--scrolled',
          targets: '.navbar-cookoon-new-with-flash-home'
        }
      });
    }
  }
}
