import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['subPayable'];

  connect() {
    this.subPayableTargets.forEach(subPayable => {
      subPayable.querySelectorAll("option").forEach(option => {
        option.classList.add("d-none")
      })
    })
  }
}
