import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["form", 'discount', 'discountCategory', 'discountValue', "item", "category", "subCategory", "description", "subPayableId", "quantity", "unitPurchasePrice", "margin", "purchasePrice", "salePrice", "skipDefaultValues", "addNewItemButton", "totalSalePrice", "totalSalePriceDiscounted", "totalMargin", "totalMarginPercentage", "totalTaxes", "totalSalePriceDiscountedWithTax"]

  payableType = this.data.get('payableType');
  payableId = this.data.get('payableId');

  connect() {
    $('.sub-total') & $('.sub-total').remove()
    $('.select2') & $('.select2').remove()
    $('.category-title') & $('.category-title').remove()
    $('.discounts') & $('.discounts').remove()
    const categories = [... new Set(this.categoryTargets.map((target) => target.value))]
    this.addTitlesAndMarginInputs()
    this.addSubTotals(categories)
    this.initSelect2forWines($('.wines'))
    categories.forEach(category => {
      this.updateSubTotalAmounts(category)
    })
    this.updateTotalAmounts()
  }

  updateTotalAmounts() {
    const query = new URLSearchParams({items: this.itemsParams(), discounts: this.discountsParams()}).toString()
    const url = `/custom/${this.payableType.toLowerCase()}s/${this.payableId}/items/total_amounts?${query}`

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data) {
          Object.keys(data).map(key => {
            return [
              key,
              key.replace('_cents', '').split('_').map((e, i) => {
                if (i === 0) {
                  return (e.charAt(0) + e.slice(1))
                } else {
                  return (e.charAt(0).toUpperCase() + e.slice(1))
                }
              }).join('')
            ]
          }).forEach((element) => {
            if (typeof(data[element[0]]) === 'number') {
              document.querySelector(`[data-target='custom-new-items.${element[1]}']`).innerText = (data[element[0]] / 100.0).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
            } else {
              document.querySelector(`[data-target='custom-new-items.${element[1]}']`).innerText = data[element[0]]
            }
          })
        }
      })
      .catch(error => console.log(error))
  }

  updateSubTotalAmounts(category) {
    const query = new URLSearchParams({items: this.itemsParams(), discounts: this.discountsParams(), category: category}).toString()
    const url = `/custom/${this.payableType.toLowerCase()}s/${this.payableId}/items/amounts_for_category?${query}`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.amountsForCategory) {
          const amountsForCategory = data.amountsForCategory
          if (amountsForCategory.salePriceDiscounted === amountsForCategory.salePrice && amountsForCategory.salePriceDiscountedWithTax === amountsForCategory.salePriceWithTax) {
            document.getElementById(`${category}-subtotal`).innerHTML = `
              <p class="mb_0">Sous-total: ${amountsForCategory.salePriceWithTax}TTC / ${amountsForCategory.salePrice}HT / Marge: ${amountsForCategory.margin}HT / ${amountsForCategory.marginPercentage}</p>
            `
          }
          else {
            document.getElementById(`${category}-subtotal`).innerHTML = `
              <p class="mb_0">Sous-total: ${amountsForCategory.salePriceWithTax}TTC / ${amountsForCategory.salePrice}HT</p>
              <p class="mb_0">Sous-total remisé: ${amountsForCategory.salePriceDiscountedWithTax}TTC / ${amountsForCategory.salePriceDiscounted}HT / Marge: ${amountsForCategory.margin}HT / ${amountsForCategory.marginPercentage}</p>
            `
          }
        }
      })
      .catch(error => console.log(error))
  }

  discountsParams() {
    const results = {}
    this.discountTargets.forEach((element, index) => {
      results[index] = {
        category: element.querySelector("[data-target='custom-new-items.discountCategory']").value,
        value: parseFloat(element.querySelector("[data-target='custom-new-items.discountValue']").value),
      }
    })
    return JSON.stringify(results)
  }

  itemsParams() {
    const results = {}
    this.itemTargets.forEach((element, index) => {
      results[index] = {
        category: element.querySelector("[data-target='custom-new-items.category']").value,
        purchase_price_cents: parseInt(element.querySelector("[data-target='custom-new-items.purchasePrice']").value.replace(' ', '').replace(',', '')),
        sale_price_cents: parseInt(element.querySelector("[data-target='custom-new-items.salePrice']").value.replace(' ', '').replace(',', '')),
      }
    })
    return JSON.stringify(results)
  }

  initSelect2forWines = (wines) => {
    wines.select2({
      placeholder: 'Veuillez sélectionner'
    }).on('select2:select', (event) => {
      this.fillWineValues(event.currentTarget, event.params.data.id)
    })
  }

  itemContainer = (element) => {
    return element.closest("[data-target='custom-new-items.item']")
  }

  discountContainer = (element) => {
    return element.closest("[data-target='custom-new-items.discount']")
  }

  fillWineValues(wineInput, wineId) {
    const itemContainer = this.itemContainer(wineInput, wineId)
    const url = `/custom/wines/${wineId}`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.wine) {
          Object.entries(data.wine).forEach(([key, value]) => {
            const element = itemContainer.querySelector(`[data-target='custom-new-items.${key}']`)
            if (element) {
              element.value = value
            }
          })
          this.updateItemAmounts(itemContainer).then(() => {
            this.updateTotalAmounts()
            this.updateSubTotalAmounts('wine')
          })
        }
      })
      .catch(error => console.log(error))
  }

  addNewItem(event) {
    const itemContainer = this.itemContainer(event.currentTarget)

    $(itemContainer).find(".wines:first").select2('destroy')

    const newItemContainer = itemContainer.cloneNode(true)

    const newNumber = this.itemTargets.length

    newItemContainer.querySelectorAll(".form-group .form-control").forEach(input => {
      input.setAttribute(
        "name",
        input.getAttribute("name") && input.getAttribute("name").split("][").map((element, index) => {
          return index === 1 ? newNumber : element
        }).join("][")
      )

      input.id = input.id.split("_").map((element, index) => {
        return index === 3 ? newNumber : element
      }).join("_")
    })

    Object.create(["quantity", "unitPurchasePrice", "margin"]).forEach((element) => {
      newItemContainer.querySelector(`[data-target='custom-new-items.${element}']`).value = 0.00
    })

    if (newItemContainer.querySelector("[data-target='custom-new-items.subPayableId'] option[selected]")) {
      newItemContainer.querySelector("[data-target='custom-new-items.subPayableId'] option[selected]").selected = false
    }

    if (itemContainer.querySelector("[data-target='custom-new-items.addNewItemButton']")) {
      itemContainer.querySelector("[data-target='custom-new-items.addNewItemButton']").remove()
    }

    this.updateItemAmounts(newItemContainer)

    this.initSelect2forWines($([itemContainer, newItemContainer]).find('.wines:first'))

    itemContainer.parentNode.insertBefore(newItemContainer, itemContainer.nextSibling)
  }

  updateDiscounts(event) {
    const category = event.currentTarget.getAttribute("data-category")
    this.discountContainer(
      document.querySelector(
        `[data-target='custom-new-items.discountCategory'][value=${category}]`
      )
    ).querySelector(
      "input[data-target='custom-new-items.discountValue']"
    ).value = event.currentTarget.value
    this.updateTotalAmounts()
    this.updateSubTotalAmounts(category)
  }

  updateAmounts(event) {
    if (!event.currentTarget.value) {
      event.currentTarget.value = 0
    }

    const itemContainer = this.itemContainer(event.currentTarget)
    this.updateItemAmounts(itemContainer).then(() => {
      this.updateTotalAmounts()
      this.updateSubTotalAmounts(itemContainer.querySelector("[data-target='custom-new-items.category']").value)
    });
  }

  updateItemAmounts = async (itemContainer) => {
    const item = this.itemParams(itemContainer)
    const query = new URLSearchParams({item}).toString()
    const url = `/custom/${this.payableType.toLowerCase()}s/${this.payableId}/items/amounts?${query}`

    return fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.amounts) {
          // Object.entries(Object.assign(data.item, data.amounts)).forEach(([key, value]) => {
          Object.entries(data.amounts).forEach(([key, value]) => {
            const element = itemContainer.querySelector(`[data-target='custom-new-items.${key}']`)
            element.value = value
          })
        }
      })
      .catch(error => console.log(error))
  }

  itemParams(itemContainer) {
    return JSON.stringify({
      category: itemContainer.querySelector("[data-target='custom-new-items.category']").value,
      sub_category: itemContainer.querySelector("[data-target='custom-new-items.subCategory']").value,
      description: itemContainer.querySelector("[data-target='custom-new-items.description']").value,
      sub_payable_id: itemContainer.querySelector("[data-target='custom-new-items.subPayableId']") ? itemContainer.querySelector("[data-target='custom-new-items.subPayableId']").value : "",
      skip_default_values: itemContainer.querySelector("[data-target='custom-new-items.skipDefaultValues']").value,
      quantity: itemContainer.querySelector("[data-target='custom-new-items.quantity']").value,
      unit_purchase_price: itemContainer.querySelector("[data-target='custom-new-items.unitPurchasePrice']").value,
      margin: itemContainer.querySelector("[data-target='custom-new-items.margin']").value,
      sale_price: itemContainer.querySelector("[data-target='custom-new-items.salePrice']").value,
    })
  }

  addSubTotals(categories) {
    categories.forEach((category) => {
      const lastItem = this.itemContainer(this.categoryTargets.findLast(target => target.value === category))
      lastItem.insertAdjacentHTML(
        "afterend",
        `<div class="row mx-0 mt-3 sub-total">
          <div class="col-11 px-0 my-auto d-flex flex-column align-items-end" id=${category}-subtotal></div>
          <div class="col-1 px-0 my-auto"></div>
        </div>`
      )
    })
  }

  addTitlesAndMarginInputs() {
    //const categories = [...new Set(
    //   this.categoryTargets.map((element) => {
    //     return element.value
    //   })
    // )]

    const categories = {
      cookoon: "Lieu - TVA 0%",
      menu: "Menu, service et autres - TVA 10%",
      wine: "Vins - TVA 20%",
      other: "Autres - TVA 20%"
    }

    const discounts = Object.fromEntries(Array.from(this.discountTargets).map(target => {
      return Array.from(target.querySelectorAll('input')).map(input => {
        return input.value
      })
    }))

    Object.entries(categories).forEach(([category, textToDisplay]) => {
      if (this.categoryTargets.find(target => target.value === `${category}`)) {
        const firstItem = this.itemContainer(this.categoryTargets.find(target => target.value === `${category}`))
        firstItem.insertAdjacentHTML("beforebegin", `<h3 class='pt-4 category-title'>${textToDisplay}</h3>`)
        if (Object.keys(discounts).includes(category)) {
          firstItem.insertAdjacentHTML(
            "beforebegin",
            `<fieldset class="form-group radio_buttons required form-group-valid discounts">
              ${this.setDiscountInputs(discounts, category)}
            </fieldset>`
          )
        }
        // firstItem.insertAdjacentHTML(
        //   "beforebegin",
        //   `<div class='row mx-0 text-center'>
        //     <div class='border border-grey col-1 p-2'>Quantité</div>
        //     <div class='border border-grey col-2 p-2'>Description</div>
        //     <div class='border border-grey col-3 p-2'>Sélection</div>
        //     <div class='border border-grey col-1 p-2'>Prix unitaire d'achat HT</div>
        //     <div class='border border-grey col-1 p-2'>Taux de marge</div>
        //     <div class='border border-grey col-1 p-2'>Prix d'achat HT</div>
        //     <div class='border border-grey col-1 p-2'>Prix de vente sans remise HT</div>
        //     <div class='border border-grey col-1 p-2'>Prix de vente HT</div>
        //     <div class='border border-grey col-1 p-2'>Nouveau</div>
        //   </div>`
        // )
      }
    })
    this.setInitialDiscounts(discounts)
  }

  setDiscountInputs(discounts, category) {
    return new Array("0.0", "0.1").map(discount => {
      return `<div class="form-check">
        <input class="form-check-input is-valid radio_buttons required" type="radio" value=${discount} name=${category}-discount id=${category}-discount-${parseInt(100 * discount)} data-action='change->custom-new-items#updateDiscounts' data-category=${category}>
        <label class="collection_radio_buttons" for=${category}-discount-${parseInt(100 * discount)}>Remise de ${parseInt(100 * discount)}%</label>
      </div>`
    }).join('')
  }

  setInitialDiscounts(discounts) {
    Object.entries(discounts).forEach(([category, discount]) => {
      document.getElementById(`${category}-discount-${parseInt(100 * discount)}`).checked = true
    })
  }
}
