import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = [
    'form',
    'ibanElement',
    'accountToken',
    'accountError',
    'ibanToken',
    'ibanError',
    'tosAcceptance',
    'tosAcceptanceError',
    'businessType',
    'submitButton',
    'firstName',
    'lastName',
    'address',
    'postalCode',
    'city',
    'companyName',
    'companyInputs',
    'individualInputs'
  ];

  stripe = Stripe(this.data.get('publishableKey'));

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('submit', this.handleSubmit);
    } // TODO: FC 08feb18 configure Turbolinks cache to remove this?

    this.mountIban();

    this.tosAcceptanceTarget.addEventListener('change', () => {
      this.toggleTosAcceptanceErrorText()
    })

    $(this.businessTypeTargets).on('change', (event) => {
      this.toggleInputsClassAndLabel(event.currentTarget.value)
    })
  }

    mountIban() {
    const style = {
      base: {
        fontSize: '16px',
        color: '#495057'
      }
    };

    const options = {
      style,
      supportedCountries: ['SEPA'],
      placeholderCountry: 'FR'
    };

    this.iban = this.stripe.elements().create('iban', options);

    this.iban.mount(this.ibanElementTarget);

    this.iban.on('change', ({ error }) => {
      const displayError = this.ibanErrorTarget;
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  disconnect() {
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener('submit', this.handleSubmit);
    } // TODO: FC 08feb18 configure Turbolinks cache to remove this?
  }

  handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();
    this.disableButton()

    if (this.tosAcceptanceTarget.checked) {
      const businessType = this.businessTypeTarget.closest('fieldset').querySelector("input:checked").value
      let accountResult
      if (businessType === 'individual') {
        accountResult = await this.stripe.createToken('account', {
          business_type: businessType,
          individual: {
            first_name: this.firstNameTarget.value,
            last_name: this.lastNameTarget.value,
            dob: {
              day: parseInt(document.getElementById('stripe_account_dob_3i').value),
              month: parseInt(
                document.getElementById('stripe_account_dob_2i').value
              ),
              year: parseInt(document.getElementById('stripe_account_dob_1i').value)
            },
            address: {
              line1: this.addressTarget.value,
              postal_code: this.postalCodeTarget.value,
              city: this.cityTarget.value
            }
          },
          tos_shown_and_accepted: this.tosAcceptanceTarget.checked,
        });
      } else if (businessType === 'company') {
        accountResult = await this.stripe.createToken('account', {
          business_type: businessType,
          company: {
            name: this.companyNameTarget.value,
            address: {
              line1: this.addressTarget.value,
              city: this.cityTarget.value,
              postal_code: this.postalCodeTarget.value,
            },
          },
          tos_shown_and_accepted: this.tosAcceptanceTarget.checked,
        });
      }

      const ibanResult = await this.stripe.createToken(this.iban, {
        currency: 'eur',
        account_holder_type: this.businessTypeTarget.closest('fieldset').querySelector("input:checked").value,
      });


      if (accountResult.token && ibanResult.token) {
        this.handleStripeTokensSubmit(accountResult.token, ibanResult.token)
      } else {
        if (accountResult.error) {
          this.accountErrorTarget.innerHTML = `<span>Une erreur est survenue avec notre prestataire de paiement, veuillez contacter la conciergerie Cookoon au <a href="tel:33-0-1-83-62-91-72">+ 33 (0)1 83 62 91 72</a> ou par mail sur <a href="mailto:concierge@cookoon.fr">concierge@cookoon.fr</a></span>`
          // this.accountErrorTarget.textContent = accountResult.error.message;
        }
        if (ibanResult.error) {
          this.ibanErrorTarget.textContent = ibanResult.error.message;
        }
        this.enableButton()
      }
    } else {
      this.tosAcceptanceErrorTarget.textContent = "Vous devez valider les conditions générales d'utilisation, les conditions générales d'utilisation des comptes connectés Stripe et les conditions générales d'utilisation Stripe";
      this.enableButton()
    }
  };

  handleStripeTokensSubmit = (accountToken, ibanToken) => {
    this.accountTokenTarget.value = accountToken.id;
    this.ibanTokenTarget.value = ibanToken.id;
    this.formTarget.removeEventListener('submit', this.handleSubmit);
    Rails.fire(this.formTarget, 'submit');
  };

  toggleClass = (element, classOne, classTwo) => {
    if (element.classList.contains(classOne)) {
      element.classList.remove(classOne)
    }
    element.classList.add(classTwo)
  }

  disableButton() {
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.getAttribute('data-disable-with');
    this.submitButtonTarget.disabled = true;
  }

  enableButton() {
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.getAttribute('data-enable-with');
    this.submitButtonTarget.disabled = false;
  }

  toggleTosAcceptanceErrorText() {
    if (this.tosAcceptanceTarget.checked) {
      this.tosAcceptanceErrorTarget.textContent = ''
    } else {
      this.tosAcceptanceErrorTarget.textContent = "Vous devez valider les conditions générales d'utilisation, les conditions générales d'utilisation des comptes connectés Stripe et les conditions générales d'utilisation Stripe";
    }
  }

  toggleInputsClassAndLabel = (businessType) => {
    if (businessType === 'individual') {
      this.toggleClass(this.companyInputsTarget, 'visible', 'invisible')
      this.toggleClass(this.companyInputsTarget, 'mb-5', 'mb-0')
      this.toggleClass(this.individualInputsTarget, 'invisible', 'visible')
      this.toggleClass(this.individualInputsTarget, 'mb-0', 'mb-5')
      this.addressTarget.closest('.form-group').querySelector('label').innerHTML = "Votre adresse (numéro et rue) <abbr title='requis'>*</abbr>"
      this.postalCodeTarget.closest('.form-group').querySelector('label').innerHTML = "Votre code postal <abbr title='requis'>*</abbr>"
      this.cityTarget.closest('.form-group').querySelector('label').innerHTML = "Votre ville <abbr title='requis'>*</abbr>"
    } else if (businessType === 'company') {
      this.toggleClass(this.companyInputsTarget, 'invisible', 'visible')
      this.toggleClass(this.companyInputsTarget, 'mb-0', 'mb-5')
      this.toggleClass(this.individualInputsTarget, 'visible', 'invisible')
      this.toggleClass(this.individualInputsTarget, 'mb-5', 'mb-0')
      this.addressTarget.closest('.form-group').querySelector('label').innerHTML = "Adresse de la société (numéro et rue) <abbr title='requis'>*</abbr>"
      this.postalCodeTarget.closest('.form-group').querySelector('label').innerHTML = "Code postal de la société <abbr title='requis'>*</abbr>"
      this.cityTarget.closest('.form-group').querySelector('label').innerHTML = "Ville de la société <abbr title='requis'>*</abbr>"
    }
  }
}
