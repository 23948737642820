import { Controller } from 'stimulus'
import flatpickr from 'vendor/flatpickr';

export default class extends Controller {
  static targets = ['datesSelection', 'startAt', 'endAt'];

  connect() {
    this.initFlatpickr(this.datesSelectionTarget)
  }

  initFlatpickr(dateInput) {
    flatpickr(dateInput, {
      mode: "range",
      altInput: true,
      altFormat: "D j F",
      dateFormat: "Y-m-d",
      minDate: "today",
      maxDate: new Date().setFullYear(new Date().getFullYear() + 1),
    });
  }
}
