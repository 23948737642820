import { Controller } from 'stimulus';
import Rails from 'rails-ujs';
import { csrfToken } from "rails-ujs"

export default class extends Controller {
  static targets = ["form", "generalStateInput"]

  connect() {
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
    document.querySelectorAll("ul.nav>li>a, .form-check input, .btn").forEach((link) => {
      link.setAttribute("disabled", true)
    })
    document.querySelectorAll(".btn").forEach((btn) => {
      btn.setAttribute("disabled", true)
      btn.removeAttribute("href")
    })
  }

  fillFormAndSubmit() {
    const checkboxesChecked = this.generalStateInputTargets.filter(checkbox => {
      return checkbox.checked
    }).forEach(checkbox => {
      checkbox.checked = false
    })

    event.target.getAttribute('data-general-state').split(', ').forEach((valueToCheck) => {
      this.generalStateInputTargets.find(checkbox => checkbox.value === valueToCheck).checked = true
    })

    this.submitForm()

    // this.cookoonButlerStateInputTargets.filter(checkbox => {
    //   return checkbox.checked === false
    // }).forEach(checkbox => {
    //   checkbox.checked = true
    // })

    // this.startDateInputTarget.value = event.target.getAttribute('data-start-date')
    // this.endDateInputTarget.value = event.target.getAttribute('data-end-date')
  }

  sendCustomerReminder(event) {
    event.preventDefault()
    const reservationCard = event.currentTarget.closest(".row")
    const url = event.currentTarget.href

    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "application/json", "X-CSRF-Token": csrfToken() },
      body: new FormData()
    })
    .then(response => response.json())
    .then((data) => {
      if (data.newReservationCard) {
        reservationCard.outerHTML = `${data.newReservationCard}<div class="alert alert-success alert-dismissible">L'email de rappel avec les instructions d'accès a bien été envoyé.<div>`
      } else {
        reservationCard.insertAdjacentHTML("afterend", `<div class="alert alert-danger alert-dismissible">Une erreur est survenue. L'email de rappel avec les instructions d'accès n'a pas pu être envoyé.<div>`)
      }
    })
  }
}
