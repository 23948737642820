import { Controller } from 'stimulus';
import Rails from 'rails-ujs';
import './bill.scss'

export default class extends Controller {
  static targets = []

  connect() {
  }
}
