import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["video", "videoContainer", "playContainer"]

  connect() {
    if (this.hasVideoTarget) {
      if (this.videoTarget.dataset.autoplay) {
        ['fas', 'fa-spinner', 'fa-spin', 'co', 'icon-cookoon-logo'].forEach((className) => {
          this.playContainerTarget.querySelector('i').classList.toggle(className)
        })
        this.toggleClasses([this.playContainerTarget, this.videoContainerTarget])
      } else {
        this.playContainerTarget.style.cursor = "auto"
      }
      const videoDuration = JSON.parse(this.videoTarget.dataset.videoDuration)
      const containerClass = this.element.classList.value
      let cloudinaryVideoUrl = this.videoTarget.dataset.src
      // const initialHeight = this.height(this.element)
      // const initialWidth = this.width(this.element)
      // cloudinaryVideoUrl = cloudinaryVideoUrl.replace('w_0', `w_${initialWidth},h_${initialHeight}`)
      cloudinaryVideoUrl = cloudinaryVideoUrl.replace(',w_0', "")

      const videoHtml = `<video
        ${/*this.videoTarget.dataset.fullSize && `data-full-size="${this.videoTarget.dataset.fullSize}"`*/''}
        ${/*this.videoTarget.dataset.fullSize ? `class="w-100 h-100"` : `class="w-100 h-100"`*/''}
        ${/*this.videoTarget.dataset.fullSize ? `class="w-100"` : `class="w-100 h-100"`*/''}
        class="w-100 h-100"
        ${this.videoTarget.dataset.style && `style="${this.videoTarget.dataset.style}"`}
        ${this.videoTarget.dataset.autoplay && `autoplay=${this.videoTarget.dataset.autoplay}`}
        ${this.videoTarget.dataset.muted && `muted=${this.videoTarget.dataset.muted}`}
        ${this.videoTarget.dataset.loop && `loop=${this.videoTarget.dataset.loop}`}
        ${this.videoTarget.dataset.controls && `controls=${this.videoTarget.dataset.controls}`}
        ${this.videoTarget.dataset.poster && `poster=${this.videoTarget.dataset.poster}`}
        playsinline=true ${/*for iphone*/''}
        src=${cloudinaryVideoUrl}
        ></video>`
      this.videoTarget.outerHTML = videoHtml
      const video = this.element.querySelector("video");

      let firedOnEnd = false
      let firedOnStart = true

      if (video.autoplay) {
        // video.addEventListener('canplay', () => {
        //   setTimeout(() => {
        //     this.toggleClasses([this.playContainerTarget, this.videoContainerTarget])
        //   }, 2000);
        //   video.addEventListener("timeupdate", () => {
        //     if (firedOnEnd === false && (videoDuration - video.currentTime < 0.8)) {
        //       firedOnEnd = true
        //       firedOnStart = false
        //       this.toggleClasses([this.playContainerTarget, this.videoContainerTarget])
        //     }
        //     if (firedOnStart === false && (videoDuration - video.currentTime > 0.8)) {
        //       firedOnEnd = false
        //       firedOnStart = true
        //       this.toggleClasses([this.playContainerTarget, this.videoContainerTarget])
        //     }
        //   })
        // }, { once: true})
      } else {
        // video.addEventListener('canplay', () => { // canplay not supported by iphone
          video.addEventListener('loadedmetadata', () => {
          ['fas', 'fa-spinner', 'fa-spin', 'fa-circle-play'].forEach((className) => {
            this.playContainerTarget.querySelector('i').classList.toggle(className)
          })

          this.playContainerTarget.style.cursor = "pointer"

          this.playContainerTarget.addEventListener('click', () => {
            this.toggleClasses([this.playContainerTarget, this.videoContainerTarget])
            // video.dataset.fullSize && this.element.classList.value && this.element.classList.remove(containerClass)
            this.element.classList.value && this.element.classList.remove(containerClass)
            video.play()
          })

          video.addEventListener("timeupdate", () => {
            if (firedOnEnd === false && (videoDuration - video.currentTime < 0.8)) {
              firedOnEnd = true
              // video.dataset.fullSize && !this.element.classList.value && this.element.classList.add(containerClass)
              !this.element.classList.value && this.element.classList.add(containerClass)
              this.toggleClasses([this.playContainerTarget, this.videoContainerTarget])
            }
          })

          video.addEventListener("ended", () => {
            firedOnEnd = false
          })
        }, {once: true})
      }
    }
  }

  toggleClasses = (elements) => {
    elements.forEach((element) => {
      ['invisible', 'visible', 'h-100', 'w-100'].forEach((value) => {
        element.classList.toggle(value)
      })
    })
  }

  // height = (element) => {
  //   return element.offsetHeight + parseInt(getComputedStyle(element)['margin-bottom']) + parseInt(getComputedStyle(element)['margin-top'])
  // }

  // width = (element) => {
  //   return element.offsetWidth + parseInt(getComputedStyle(element)['margin-left']) + parseInt(getComputedStyle(element)['margin-right'])
  // }
}
