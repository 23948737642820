import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "elementToToggle" ]

  connect() {
  }

  toggle() {
    this.elementToToggleTarget.classList.toggle('visible');
    this.elementToToggleTarget.classList.toggle('invisible');
    this.elementToToggleTarget.classList.toggle('d-none');
  }
}
