import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["salePriceWithTax", "salePrice", "purchasingPrice", "margin", "aasmState", "select2WithTag", "select2", "country", "region"]

  disconnect() {
  }

  connect = async() => {
    this.initSelect2()
    const regionsForCountries = await this.regionsForCountries()
    $(this.countryTarget).on('select2:select', () => {
      this.updateSelect2Options(regionsForCountries[$(this.countryTarget).val()])
    })
  }

  regionsForCountries = async () => {
    const url = `/admin/wines/regions_for_country`
    const response = await fetch(url);
    const data = await response.json();
    return data
  }

  initSelect2() {
    $(this.select2WithTagTargets).select2({
      tags: true,
      placeholder: "Veuillez sélectionner ou entrer une nouvelle valeur",
    })
    $(this.select2Targets).select2({
      placeholder: "Veuillez sélectionner",
    })
  }

  updateSelect2Options = (regionsForCountry) => {
    let data
    if (regionsForCountry) {
      data = regionsForCountry.map((element) => {
        return Object.fromEntries([['id', element], ['text', element]])
      })
    } else {
      data = []
    }
    $(this.regionTarget).empty().select2({
      data: data,
      tags: true,
      placeholder: "Veuillez sélectionner ou entrer une nouvelle valeur",
      language: {
        noResults: ''
      }
    }).val('').trigger('change')
  }

  setNewSalePriceWithTax() {
    const wine = this.wineParams()
    const query = new URLSearchParams({wine}).toString()
    const url = `/admin/wines/amounts.json?${query}`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.salePriceWithTaxTarget.innerText = data.sale_price_with_tax
        this.salePriceTarget.innerText = data.sale_price
      })
  }

  wineParams() {
    return JSON.stringify({
      purchasing_price: this.purchasingPriceTarget.value,
      margin: this.marginTarget.value,
      aasm_state: this.aasmStateTarget.querySelector('input[type = radio]:checked').value,
    })
  }
}
