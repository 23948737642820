import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'image', 'text']

  connect() {
    $(this.imageTarget).lazy({
      // delay: 1000,
      afterLoad: () => {
        if (this.hasTextTarget) {
          this.textTarget.style.opacity = 1
        }
      },
    });
    // if (this.imageTarget.dataset.fullSize) {
    //   $(this.imageTarget).lazy({
    //     // delay: 1000,
    //     afterLoad: () => {
    //       // this.containerTarget.style.height = 'unset'
    //       // this.containerTarget.style.aspectRatio = 'unset'
    //       if (this.hasTextTarget) {
    //         this.textTarget.style.opacity = 1
    //       }
    //     },
    //   });
    // } else {
    //   this.resizeImageToFillContainer()

    //   const imageHTML = this.imageTarget.outerHTML
    //   const textHTML = this.hasTextTarget && this.textTarget.outerHTML
    //   let timeout;

    //   window.addEventListener('resize', () => {
    //     resizeImageObserver.observe(this.containerTarget)
    //   }, { once: true })

    //   const resizeImageObserver = new ResizeObserver((entries) => {
    //     clearTimeout(timeout)
    //     const container = entries[0].target
    //     this.removeElements([container.querySelector("img"), container.querySelector(".text-area")]);

    //     timeout = setTimeout(() => {
    //       const newImageHTML = imageHTML
    //       const newTextHTML = textHTML
    //       container.insertAdjacentHTML('beforeend', newImageHTML);
    //       container.querySelector("img").dataset.src = container.querySelector("img").dataset.imgSrc.replace('w_0', `w_${parseInt(entries[0].contentBoxSize[0].inlineSize)},h_${parseInt(entries[0].contentBoxSize[0].blockSize)}`)
    //       $(container.querySelector("img")).lazy({
    //         // delay: 1000,
    //         afterLoad: () => {
    //           if (newTextHTML) {
    //             container.insertAdjacentHTML('beforeend', newTextHTML);
    //             container.querySelector(".text-area").style.opacity = 1
    //           }
    //         }
    //       });
    //     }, 1500)
    //   })
    // }
  }

  resizeImageToFillContainer() {
    this.imageTarget.dataset.src = this.imageTarget.dataset.imgSrc.replace('w_0', `w_${this.containerTarget.offsetWidth},h_${this.containerTarget.offsetHeight}`)
    $(this.imageTarget).lazy({
      // delay: 10000,
      afterLoad: () => {
        if (this.hasTextTarget) {
          this.textTarget.style.opacity = 1
        }
      }
    });
  }

  removeElements = (elements) => {
    elements.forEach(element => {
      element && element.remove()
    })
  }
}
