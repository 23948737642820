import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['imageNextToTextCardTextPart']

  connect() {
    window.addEventListener("load", (event) => {
      this.imageNextToTextCardTextPartTargets.forEach (item => {
        const itemTitle = item.querySelector(".image_next_to_text_card_title")
        const itemDescription = item.querySelector(".image_next_to_text_card_description")
        const itemTitleHeight = itemTitle.offsetHeight + parseInt(getComputedStyle(itemTitle)['margin-bottom']) + parseInt(getComputedStyle(itemTitle)['margin-top'])
        const itemDescriptionSizeAvailable = item.offsetHeight - itemTitleHeight
        const itemDescriptionLineHeight = parseInt(getComputedStyle(itemDescription)['line-height'])
        const itemDescriptionLinesAvailable = parseInt(
          itemDescriptionSizeAvailable / itemDescriptionLineHeight
        )
        itemDescription.style['-webkit-line-clamp'] = itemDescriptionLinesAvailable
      })
    });
  }
}
