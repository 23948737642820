import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['body', 'trigger', 'content'];

  connect() {
    if (this.hasContentTarget) {
      $(this.contentTarget).on('show.bs.collapse', (event) => {
        const homeDropdown = event.currentTarget.closest(".home-dropdown")
        const chevronIcon = homeDropdown.querySelector("i.fa-chevron-right")
        const titleIcon = homeDropdown.querySelector("i.co")
        homeDropdown.classList.add("border", "border-primary")
        chevronIcon.classList.add("fa-chevron-up", "text-primary")
        chevronIcon.classList.contains("fa-chevron-right") && chevronIcon.classList.remove("fa-chevron-right")
        titleIcon.classList.add("text-primary")
      })

      $(this.contentTarget).on('hide.bs.collapse', (event) => {
        const homeDropdown = event.currentTarget.closest(".home-dropdown")
        const chevronIcon = homeDropdown.querySelector("i.fa-chevron-up")
        const titleIcon = homeDropdown.querySelector("i.co")
        homeDropdown.classList.contains("border") && homeDropdown.classList.contains("border-primary") && homeDropdown.classList.remove("border", "border-primary")
        chevronIcon.classList.add("fa-chevron-right")
        chevronIcon.classList.contains("fa-chevron-up") && chevronIcon.classList.remove("fa-chevron-up")
        chevronIcon.classList.contains("text-primary") && chevronIcon.classList.remove("text-primary")
        titleIcon.classList.contains("text-primary") && titleIcon.classList.remove("text-primary")
      })
    }
  }

  toggle() {
    this.bodyTarget.classList.toggle('navigation-dropdown-menu--opened')
  }

  close() {
    if (!this.triggerTargets.includes(event.target)) {
      this.bodyTarget.classList.remove('navigation-dropdown-menu--opened')
    }
  }
}
