import { Controller } from 'stimulus';
import 'jquery-ui';
import 'blueimp-file-upload';
import 'cloudinary-jquery-file-upload';
import 'vendor/attachinary';

export default class extends Controller {
  connect() {
    $('.attachinary-input').attachinary();

    // Display upload-zone if attachinary-input is not disabled
    $('.attachinary-input').each(function() {
      if (!($(this)[0] && $(this)[0].disabled)) {
        $("label[for='"+$(this).attr('id')+"']").addClass('upload-zone');
      }
    });
    // Old code that was ok for only one attachinary input
    // if (!($('.attachinary-input')[0] && $('.attachinary-input')[0].disabled)) {
    //   $('label.attachinary').addClass('upload-zone');
    // }

    // Remove upload-zone if last uploaded picture disables attachinary-input
    $('.attachinary-input').each(function() {
      $(this).on('fileuploaddone', function(event) {
        if (event.result[0].disabled) {
          $("label[for='"+$(this).attr('id')+"']").removeClass('upload-zone');
        }
      });
    });
    // Old code that was ok for only one attachinary input
    // $('.attachinary-input').on('fileuploaddone', function(event) {
    //   if (event.result[0].disabled) {
    //     $('label.attachinary').removeClass('upload-zone');
    //   }
    // });

    // Add upload-zone if picture deleted
    $('.attachinary-input').each(function() {
      $(this).on('attachinary:fileremoved', function(event) {
        $("label[for='"+$(this).attr('id')+"']").addClass('upload-zone');
      });
    });
    // Old code that was ok for only one attachinary input
    // $(document).on('attachinary:fileremoved', function() {
    //   $('label.attachinary').addClass('upload-zone');
    // })

    $('.attachinary-input').each(function() {
      $(this).on('fileuploadadd', function(event, data) {
        const invalidFeedback = event.currentTarget.closest(".form-group").querySelector(".invalid-feedback")
        if (invalidFeedback) {
          invalidFeedback.remove()
        }

        const MbSize = (data.files[0].size / 1024.0) / 1024
        const type = data.files[0].type.split('/')[0]

        if ($('.form-group.attachinary .invalid-feedback')) {
          $('.form-group.attachinary .invalid-feedback').remove()
        }

        if (event.currentTarget.dataset && event.currentTarget.dataset.format && type != event.currentTarget.dataset.format) {
          event.preventDefault()
          if (event.currentTarget.dataset.format === "image") {
            $(this).parent(".form-group").append(`<div class='invalid-feedback'>Le fichier n'est pas valide, veuillez en choisir un autre au format jpeg, jpg ou png.</div>`)
          }
          else if (event.currentTarget.dataset.format === "video") {
            $(this).parent(".form-group").append(`<div class='invalid-feedback'>Le fichier n'est pas valide, veuillez en choisir un autre au format mp4, mov.</div>`)
          }
        } else if (!["image", "video"].includes(type)) {
          event.preventDefault()
          $(this).parent(".form-group").append("<div class='invalid-feedback'>Le fichier n'est pas valide, veuillez en choisir un autre.</div>")
        } else {
          if (MbSize > 100 && type === "video") {
            event.preventDefault()
            $(this).parent(".form-group").append("<div class='invalid-feedback'>La taille du fichier ne doit pas dépasser 100MB.</div>")
          }
          if (MbSize > 10 && type === "image") {
            event.preventDefault()
            $(this).parent(".form-group").append("<div class='invalid-feedback'>La taille du fichier ne doit pas dépasser 10MB.</div>")
          }
        }
      });
    });
  }
}
