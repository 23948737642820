import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // window.addEventListener("load", (event) => {
      if (document.querySelector('.modal#end_of_free_subscription_modal')) {
        setTimeout(() => {
          $('.modal#end_of_free_subscription_modal').modal('show');
          $('.modal#end_of_free_subscription_modal').on('shown.bs.modal', () => {
            //if (screen.height - 100 <= document.querySelector('#end_of_free_subscription img').offsetHeight) {
              document.querySelector('#end_of_free_subscription .fa-angles-down').classList.remove('d-none');
              document.querySelector('#end_of_free_subscription .fa-angles-down').classList.add('d-flex');
            //}
          })
        }, 1500);
      } else {
        //if (screen.height - 100 <= document.querySelector('#end_of_free_subscription img').offsetHeight) {
          document.querySelector('#end_of_free_subscription .fa-angles-down').classList.remove('d-none');
          document.querySelector('#end_of_free_subscription .fa-angles-down').classList.add('d-flex');
          // running all the time
          document.querySelector('#end_of_free_subscription .container').style.scrollMarginTop = `${document.querySelector(".navbar-cookoon-new").offsetHeight}px`;
          // not running if back browser clicked
          window.addEventListener("load", (event) => {
            document.querySelector('#end_of_free_subscription .container').style.scrollMarginTop = `${document.querySelector(".navbar-cookoon-new").offsetHeight}px`;
          })
        //}
      }
    // })
  }
}
