import { Controller } from 'stimulus';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger)
// import { PixiPlugin } from "gsap/PixiPlugin.js";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";

//without this line, PixiPlugin and MotionPathPlugin may get dropped by your bundler (tree shaking)...
// gsap.registerPlugin(PixiPlugin, MotionPathPlugin);

export default class extends Controller {
  static targets = ["grid", "item"]

  disconnect() {
    // if (this.data.get('gsap') && this.hasGridTarget && this.hasItemTarget) {
    //   gsap.to("*", {clearProps:true})
    //   gsap.from("*", {clearProps:true})
    // }
  }

  connect() {
    if (this.data.get('gsap') && this.hasGridTarget && this.hasItemTarget) {
      if (getComputedStyle(this.gridTarget).gridTemplateColumns != 'none') {
        const ypercentRight = 25
        const ypercentLeft = parseInt(ypercentRight / 2)
        this.gridTarget.style.marginBottom = `${Math.ceil(
            (ypercentLeft / 100) * (
              this.itemTarget.offsetHeight + parseInt(getComputedStyle(this.itemTarget).marginTop) + parseInt(getComputedStyle(this.itemTarget).marginBottom)
            )
          )
        }px`
        const gridLength = getComputedStyle(this.gridTarget).gridTemplateColumns.split(" ").length
        if (gridLength > 1) {
          gsap.from(Array.from(this.itemTargets).filter((element, index) => (index + 1) % gridLength == 0), {
            yPercent: ypercentRight,
            ease: "none",
            scrollTrigger: this.scrollTriggerParams(),
          });

          gsap.to(Array.from(this.itemTargets).filter((element, index) => (index) % gridLength == 0), {
            yPercent: ypercentLeft,
            ease: "none",
            scrollTrigger: this.scrollTriggerParams(),
          });
        }
      }
    }
  }

  scrollTriggerParams() {
    return {
      trigger: this.gridTarget,
      // start: "top bottom", // the default values
      start: "top center",
      // end: "bottom top",
      end: "bottom bottom",
      scrub: true,
      // markers: true
    }
  }
}
