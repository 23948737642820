import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["item", "quantity", "productCapacity"];
  shippingAddressId = this.data.get('shippingAddressId')

  connect() {
    $(this.productCapacityTargets).children("option").addClass("d-none")
  }

  updateBill() {
    const query = new URLSearchParams({sale: this.itemsParams(), shipping_address_id: this.shippingAddressId || ""}).toString()
    const url = `/sales/new.json?${query}`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data && data.bill) {
          $('#bill').replaceWith(data.bill)
        }
      })
  }

  itemsParams() {
    const results = { items_attributes: {} }
    this.itemTargets.forEach((element, index) => {
      results["items_attributes"][index] = {
        quantity: element.querySelector("[data-target='product-sales.quantity']").options[element.querySelector("[data-target='product-sales.quantity']").selectedIndex].value,
        sub_payable_id: element.querySelector("[data-target='product-sales.productCapacity']").options[element.querySelector("[data-target='product-sales.productCapacity']").selectedIndex].value,
      }
    })
    return JSON.stringify(results)
  }
}
