import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = ['noon', 'evening']

  connect() {
  }

  submitForm(event) {
    Rails.fire(event.target.closest('form'), 'submit')
  }

  selectOrDeselectRow(event) {
    event.target.closest(".row").querySelectorAll(".form-check-input.boolean.optional").forEach(input => {
      input.checked = event.target.checked
    })
    this.submitForm(event)
  }

  selectOrDeselectWeekColumn(event) {
    event.target.closest('form').querySelectorAll(`input[data-target='calendars.${event.currentTarget.value}']`).forEach(input => {
      input.checked = event.target.checked
    })
    this.submitForm(event)
  }
}
