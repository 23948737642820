import { Controller } from "stimulus"
import flatpickr from 'vendor/flatpickr'
import $ from 'jquery'
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = ["menuForm", "menuError", "menuChefId", "menuReservationStartAt", "menuReservationTypeName", "menuSubmit", "customerForm", "customerError", "customerSubmit", "form", "cookoonButlerState", "menuState", "typeName", "address", "startAt", "peopleCount", "menuId", "cookoonId", "customerId", "category", "salePriceWithTax"]
  // , "winePairingId", "winePairingQuantity", "winePairingForm", "winePairingSubmit", "winePairingError", "wine", "wineQuantity", "wineId", "additionalService", "additionalServiceQuantity", "additionalServiceCategory", "additionalServiceName", "additionalServicePurchasingUnitPrice", "additionalServiceSaleUnitPrice"

  disconnect() {
  }

  connect() {
    this.initFlatpickr(this.startAtTarget, this.startAtTarget.value)
    this.initSelect2()
    // this.googleAutocomplete()
    $(this.startAtTarget).add(this.peopleCountTarget).add(this.typeNameTarget).on('change', () => {
      this.fetchBaseUrlAndRefreshReservationAndMenuForms('/custom/reservations/new')
      // $(this.winePairingQuantityTarget).val($(this.peopleCountTarget).val()).select2().trigger("change")
    })
    // $(this.wineQuantityTargets.concat(this.wineIdTargets)).on('change', (event) => {
    //   this.newWineInput(event)
    // })
    // $(
    //   this.additionalServiceQuantityTargets.concat(
    //     this.additionalServiceCategoryTargets).concat(
    //       this.additionalServiceNameTargets).concat(
    //         this.additionalServicePurchasingUnitPriceTargets).concat(
    //           this.additionalServiceSaleUnitPriceTargets)
    // ).on('change', (event) => {
    //   this.newAdditionalServiceInput(event)
    // })
  }

  toggleCookoonButlerAndMenuState(event) {
    event.preventDefault()

    let url = new URL(event.currentTarget.href).search
    let params = new URLSearchParams(url)
    this.cookoonButlerStateTarget.value = params.get("cookoon_butler_state")
    this.menuStateTarget.value = params.get("menu_state")
    this.fetchBaseUrlAndRefreshReservationAndMenuForms('/custom/reservations/new')
  }

  // newWineInput(event) {
  //   const parent = $(event.target).parents("div[data-target='custom-new-reservation.wine']")
  //   const quantity = parent.find("select[data-target='custom-new-reservation.wineQuantity']").select2("val")
  //   const id = parent.find("select[data-target='custom-new-reservation.wineId']").select2("val")
  //   if (quantity != 0 && id != "") {
  //     this.fetchBaseUrlAndRefreshReservationAndMenuForms('/custom/reservations/new')
  //   }
  // }

  // newAdditionalServiceInput(event) {
  //   const parent = $(event.target).parents("div[data-target='custom-new-reservation.additionalService']")
  //   const quantity = parent.find("select[data-target='custom-new-reservation.additionalServiceQuantity']").select2("val")
  //   const category = parent.find("select[data-target='custom-new-reservation.additionalServiceCategory']").select2("val")
  //   const name = parent.find("input[data-target='custom-new-reservation.additionalServiceName']").val()
  //   const purchasingUnitPrice = parent.find("input[data-target='custom-new-reservation.additionalServicePurchasingUnitPrice']").val()
  //   const saleUnitPrice = parent.find("input[data-target='custom-new-reservation.additionalServiceSaleUnitPrice']").val()
  //     if (quantity != 0 && category != "" && name != "" && purchasingUnitPrice != "" && saleUnitPrice != "") {
  //     this.fetchBaseUrlAndRefreshReservationAndMenuForms('/custom/reservations/new')
  //   }
  // }

  fetchBaseUrlAndRefreshReservationAndMenuForms = (baseUrl) => {
    const reservation = this.reservationParams()
    const query = new URLSearchParams({reservation}).toString()
    const url = `${baseUrl}.json?${query}`

    this.destroySelect2AndDisableInputs()
    flatpickr(this.startAtTarget).destroy();

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.form && data.menuForm) {
          this.formTarget.outerHTML = data.form
          this.menuFormTarget.outerHTML = data.menuForm
          this.connect()
        }
      })
      .catch(error => console.log(error))
  }

  reservationParams() {
    return JSON.stringify({
      customer_id: this.customerIdTarget.value,
      cookoon_butler_state: this.cookoonButlerStateTarget.value,
      menu_state: this.menuStateTarget.value,
      type_name: this.typeNameTarget.value,
      address: this.addressTarget.value,
      start_at: this.startAtTarget.value,
      people_count: this.peopleCountTarget.value,
      cookoon_id: this.cookoonIdTarget.value,
      menu_id: this.menuIdTarget.value,
      category: this.categoryTarget.value,
      price_attributes: {
        sale_price_with_tax: this.hasSalePriceWithTaxTarget && this.salePriceWithTaxTarget.value || null,
      }
      // reservation_wine_pairing_attributes: {
      //   quantity: this.winePairingQuantityTarget.value,
      //   wine_pairing_id: this.winePairingIdTarget.value
      // },
      // reservation_wines_attributes: Object.fromEntries(
      //   this.wineTargets.map((element, index) => {
      //     return [
      //       index,
      //       Object.fromEntries(
      //         [
      //           ["quantity", $(element.querySelector("select[data-target='custom-new-reservation.wineQuantity']")).select2("val")],
      //           ["wine_id", $(element.querySelector("select[data-target='custom-new-reservation.wineId']")).select2("val")],
      //         ]
      //       )
      //     ]
      //   })
      // ),
      // additional_services_attributes: Object.fromEntries(
      //   this.additionalServiceTargets.map((element, index) => {
      //     return [
      //       index,
      //       Object.fromEntries(
      //         [
      //           ["quantity", $(element.querySelector("select[data-target='custom-new-reservation.additionalServiceQuantity']")).select2("val")],
      //           ["category", $(element.querySelector("select[data-target='custom-new-reservation.additionalServiceCategory']")).select2("val")],
      //           ["name", $(element.querySelector("input[data-target='custom-new-reservation.additionalServiceName']")).val()],
      //           ["purchasing_unit_price", $(element.querySelector("input[data-target='custom-new-reservation.additionalServicePurchasingUnitPrice']")).val()],
      //           ["sale_unit_price", $(element.querySelector("input[data-target='custom-new-reservation.additionalServiceSaleUnitPrice']")).val()]
      //         ]
      //       )
      //     ]
      //   })
      // )
    })
  }

  createReservation(event) {
    event.preventDefault();
    event.stopPropagation();

    const data = new FormData(this.formTarget);
    Rails.ajax({
      url: this.formTarget.action,
      type: this.formTarget.method,
      data,
      success: response => {
        this.connect()
      },
      error: (_jqXHR, _textStatus, errorThrown) => {
        console.log(errorThrown);
      }
    });
  }

  createCustomer(event) {
    event.preventDefault()
      fetch(this.customerFormTarget.action, {
        method: "POST",
        headers: { "Accept": "application/json" },
        body: new FormData(this.customerFormTarget)
      })
        .then(response => response.json())
        .then((data) => {
          this.customerErrorTarget.innerText = ""
          if (data.customer) {
            // Set the new customer in customers list and select it
            const newOption = new Option(data.customer.email, data.customer.id, false, true);
            $(this.customerIdTarget).append(newOption).trigger('change');
            $('#newCustomerModal').modal('hide')
          }
          this.customerFormTarget.outerHTML = data.form
        })
        .catch(error => {
          this.customerErrorTarget.innerText = error
          this.customerSubmitTarget.disabled = false
        })
  }

  // createWinePairing(event) {
  //   event.preventDefault()
  //   fetch(this.winePairingFormTarget.action, {
  //     method: "POST",
  //     headers: { "Accept": "application/json" },
  //     body: new FormData(this.winePairingFormTarget)
  //   })
  //     .then(response => response.json())
  //     .then((data) => {
  //       if (data.winePairing) {
  //         // Set the new wine_pairing in wine_pairings list and select it
  //         const newOption = new Option(`Prix de vente HT: ${data.winePairing.salePrice} / Prix d'achat HT: ${data.winePairing.purchasePrice}`, data.winePairing.id, false, true);
  //         $(this.winePairingIdTarget).append(newOption).trigger('change');
  //         $('#newWinePairingModal').modal('hide')
  //       }
  //       this.winePairingFormTarget.outerHTML = data.form
  //     })
  //     .catch(error => {
  //       this.winePairingErrorTarget.innerText = error
  //       this.winePairingSubmitTarget.disabled = false
  //     })
  // }

  createMenu(event) {
    event.preventDefault()
      fetch(this.menuFormTarget.action, {
        method: "POST",
        headers: { "Accept": "application/json" },
        body: new FormData(this.menuFormTarget)
      })
        .then(response => response.json())
        .then((data) => {
          this.menuErrorTarget.innerText = ""
          if (data.menu && data.chef) {
            // Set the new menu in menus list and select it
            const newOption = new Option(`${data.chef.name} - ${data.menu.description}`, data.menu.id, false, true);
            $(this.menuIdTarget).append(newOption).trigger('change');
            $('#newMenuModal').modal('hide')
          }
          this.menuFormTarget.outerHTML = data.form
          $(this.menuChefIdTarget).select2({
            dropdownParent: $("#newMenuModal")
          })
        })
        .catch(error => {
          this.menuErrorTarget.innerText = error
          this.menuSubmitTarget.disabled = false
        })
  }

  initFlatpickr(dateInput, defaultDate) {
    flatpickr(dateInput, {
      defaultDate: defaultDate,
      altInput: true,
      altFormat: "D j F",
      dateFormat: "Y-m-d",
    });

    if (dateInput.value === "") {
      dateInput.parentElement.childNodes[1].value = "Choisissez une date"
    }
  }

  initSelect2() {
    this.select2Inputs().forEach((input) => {
      $(input).select2()
    })
    $(this.menuChefIdTarget).select2({
      dropdownParent: $("#newMenuModal")
    })
  }

  destroySelect2AndDisableInputs() {
    this.select2Inputs().forEach((input) => {
      $(input).select2('destroy')
      input.disabled = true
    })
    $(this.menuChefIdTarget).select2('destroy', {
      dropdownParent: $("#newMenuModal")
    })
    this.menuChefIdTarget.disabled = true
  }

  select2Inputs() {
    const inputs = [this.cookoonIdTarget, this.typeNameTarget, this.peopleCountTarget, this.customerIdTarget, this.menuIdTarget]
    // , this.winePairingIdTarget, this.winePairingQuantityTarget, this.wineQuantityTargets, this.wineIdTargets, this.additionalServiceQuantityTargets, this.additionalServiceCategoryTargets
    return inputs
  }

  googleAutocomplete() {
    if (google) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.addressTarget,
        {
          types: ['geocode'],
          componentRestrictions: { country: 'fr' }
        }
      );
    }
  }
}
