import { Controller } from "stimulus"

export default class extends Controller {

  showMore() {
    const photosBlockToDisplay = document.querySelectorAll(".photos-block.d-none")

    if (photosBlockToDisplay.length >= 1) {
      photosBlockToDisplay[0].classList.toggle('d-none')
      if (photosBlockToDisplay.length === 1) {
        event.currentTarget.classList.toggle('d-none')
      }
    }
  }
}
