import { Controller } from "stimulus"
import flatpickr from 'vendor/flatpickr'
import $ from 'jquery'
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = ["customerForm", "customerError", "customerSubmit", "form", "customerId", "category"]

  disconnect() {
  }

  connect() {
    this.initSelect2()
  }

  createSale(event) {
    event.preventDefault();
    event.stopPropagation();

    const data = new FormData(this.formTarget);
    Rails.ajax({
      url: this.formTarget.action,
      type: this.formTarget.method,
      data,
      success: response => {
        this.connect()
      },
      error: (_jqXHR, _textStatus, errorThrown) => {
        console.log(errorThrown);
      }
    });
  }

  createCustomer(event) {
    event.preventDefault()
      fetch(this.customerFormTarget.action, {
        method: "POST",
        headers: { "Accept": "application/json" },
        body: new FormData(this.customerFormTarget)
      })
        .then(response => response.json())
        .then((data) => {
          this.customerErrorTarget.innerText = ""
          if (data.customer) {
            // Set the new customer in customers list and select it
            const newOption = new Option(data.customer.email, data.customer.id, false, true);
            $(this.customerIdTarget).append(newOption).trigger('change');
            $('#newCustomerModal').modal('hide')
          }
          this.customerFormTarget.outerHTML = data.form
        })
        .catch(error => {
          this.customerErrorTarget.innerText = error
          this.customerSubmitTarget.disabled = false
        })
  }

  initSelect2() {
    this.select2Inputs().forEach((input) => {
      $(input).select2()
    })
  }

  destroySelect2AndDisableInputs() {
    this.select2Inputs().forEach((input) => {
      $(input).select2('destroy')
      input.disabled = true
    })
  }

  select2Inputs() {
    const inputs = [this.customerIdTarget, this.categoryTarget]
    return inputs
  }
}
