import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = ["form", "sortByInput"]

  connect() {
  }

  setSquaredSearchSortFilterParams() {
    if (event.target.getAttribute("name") === "sort_filter[sort_by]") {
      document.querySelector("form.simple_form.edit_reservation input#sort_filter_sort_by").value = event.target.value
    } else if (event.target.getAttribute("name") === "sort_filter[filter_by][]") {
      const inputsChecked = event.target.closest('fieldset.sort_filter_filter_by').querySelectorAll("input:checked")
      const valuesChecked = []
      Array.from(inputsChecked).forEach((input) => {
        valuesChecked.push(input.value)
      })
      document.querySelector("form.simple_form.edit_reservation input#sort_filter_filter_by").value = valuesChecked
    }
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit');
    this.sortByInputTargets.forEach(element => element.disabled = true);
  }

  toggle() {
    if (event.currentTarget.closest(".sort_filter_group").querySelector(".fa-chevron-down") != null) {
      event.currentTarget.closest(".sort_filter_group").querySelector(".fa-chevron-down").classList.toggle('fa-chevron-up')
      event.currentTarget.closest(".sort_filter_group").querySelector(".fa-chevron-down").classList.toggle('fa-chevron-down')
      event.currentTarget.closest(".sort_filter_group").querySelector(".invisible").classList.toggle('visible')
      event.currentTarget.closest(".sort_filter_group").querySelector(".invisible").classList.toggle('invisible')
    } else if (event.currentTarget.closest(".sort_filter_group").querySelector(".fa-chevron-up") != null) {
      event.currentTarget.closest(".sort_filter_group").querySelector(".fa-chevron-up").classList.toggle('fa-chevron-down')
      event.currentTarget.closest(".sort_filter_group").querySelector(".fa-chevron-up").classList.toggle('fa-chevron-up')
      event.currentTarget.closest(".sort_filter_group").querySelector(".visible").classList.toggle('invisible')
      event.currentTarget.closest(".sort_filter_group").querySelector(".visible").classList.toggle('visible')
    }
  }
}
