import { Controller } from 'stimulus';
import $ from 'jquery';
import flatpickr from 'vendor/flatpickr';
import Rails from 'rails-ujs';
import Select2 from "select2";
import './squared_search.scss'

export default class extends Controller {
  static targets = ["form", "peopleCount", "startAt", "typeName", "select2", "calendar", "startAtDayName", "startAtDay", "startAtMonth", "capacityError"]

  connect() {
    const startDateAvailable = this.startAtTarget.dataset.startAtAvailable
    const endDateAvailable = this.startAtTarget.dataset.endAtAvailable
    const unavailableDatesForCookoonUrl = this.formTarget.dataset.unavailableDatesForCookoonUrl
    const unavailableDatesForChefUrl = this.formTarget.dataset.unavailableDatesForChefUrl
    const datesUnavailable = JSON.parse(this.startAtTarget.dataset.datesUnavailable)
    const cookoonCapacity = parseInt(this.formTarget.dataset.cookoonCapacity)
    const cookoonCapacityStanding = parseInt(this.formTarget.dataset.cookoonCapacityStanding)
    const chefCapacity = parseInt(this.formTarget.dataset.chefCapacity)
    let peopleCount = this.peopleCountTarget.value
    let date = this.startAtTarget.value.split(" ")[0]
    const typeNames = [...this.typeNameTarget.options].map((option) => {
      return option.value
    }).filter(Boolean)

    this.initSelect2()

    this.initFlatpickr(this.calendarTarget, this.startAtTarget, this.startAtTarget.value, startDateAvailable, endDateAvailable, datesUnavailable, true, this.startAtDayNameTarget, this.startAtDayTarget, this.startAtMonthTarget)

    $(this.peopleCountTarget).on('change', (event => {
      peopleCount = event.target.value
      // this.updateTypeNamesForPeopleCount(peopleCount, cookoonCapacity, ["lunch", "diner"])
      // this.updateTypeNamesForPeopleCount(peopleCount, cookoonCapacityStanding, ["lunch_cocktail", "diner_cocktail"])
    }))

    $(this.peopleCountTarget).on('select2:close', (event => {
      this.hideCapacityError()
    }))

    $(this.calendarTarget).on('change', (event => {
      date = event.target.value
      this.hideCapacityError()
      this.updateTypeNamesForDate(unavailableDatesForCookoonUrl, unavailableDatesForChefUrl, typeNames, date)
    }))

    $(this.typeNameTarget).on('change', (event => {
      this.hideCapacityError()
      this.updatePeopleCount(cookoonCapacity, cookoonCapacityStanding, chefCapacity)
      if (unavailableDatesForCookoonUrl || unavailableDatesForChefUrl) {
        this.unavailableDates(
          event.target.value,
          peopleCount,
          startDateAvailable,
          endDateAvailable,
          unavailableDatesForCookoonUrl,
          unavailableDatesForChefUrl
          ).then((newUnavailableDates) => {
            if (newUnavailableDates.includes(date)) {
              this.initFlatpickr(this.calendarTarget, this.startAtTarget, null, startDateAvailable, endDateAvailable, newUnavailableDates, true, this.startAtDayNameTarget, this.startAtDayTarget, this.startAtMonthTarget)
              this.startAtTarget.value = ""
              this.startAtDayNameTarget.innerText = ""
              this.startAtDayTarget.innerText = ""
              this.startAtMonthTarget.innerText = ""
            } else {
              this.initFlatpickr(this.calendarTarget, this.startAtTarget, this.startAtTarget.value, startDateAvailable, endDateAvailable, newUnavailableDates, true, this.startAtDayNameTarget, this.startAtDayTarget, this.startAtMonthTarget)
            }
          })
      } else {
      }
    }))
  }

  // updateTypeNamesForPeopleCount = (peopleCount, capacity, typeNames) => {
  //   typeNames.forEach(typeName => {
  //     if (peopleCount > capacity) {
  //       $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', true).prop('disabled', true)
  //     } else {
  //       $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', false).prop('disabled', false)
  //     }
  //   })
  // }

  updateTypeNamesForDate = (unavailableDatesForCookoonUrl, unavailableDatesForChefUrl, typeNames, date) => {
    if (unavailableDatesForCookoonUrl || unavailableDatesForChefUrl) {
      this.unavailableTypeNamesForDate(
        unavailableDatesForCookoonUrl,
        unavailableDatesForChefUrl,
        typeNames,
        date
      ).then(availabilities => {
        Object.entries(availabilities).forEach(([typeName, availability]) => {
          if (availability === "unavailable" ) {
            $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', true).prop('disabled', true)
          } else {
            $(`.reservation_type_name option[value=${typeName}]`).attr('disabled', false).prop('disabled', false)
          }
        })
        this.initSelect2()
      })
    }
  }

  unavailableTypeNamesForDate = (unavailableDatesForCookoonUrl, unavailableDatesForChefUrl, typeNames, date) => {
    const cookoonPromises = []
    const chefPromises = []
    if (unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
      typeNames.forEach(typeName => {
        cookoonPromises.push(this.isTypeNameUnavailableForSupplierAndDate(unavailableDatesForCookoonUrl, typeName, date))
        chefPromises.push(this.isTypeNameUnavailableForSupplierAndDate(unavailableDatesForChefUrl, typeName, date))
      })
      return Promise.all(
        [
          Promise.all(cookoonPromises),
          Promise.all(chefPromises)
        ]
      ).then(results => {
        const cookoonAvailabilities = this.fillSupplierAvailabilities(results[0])
        const chefAvailabilities = this.fillSupplierAvailabilities(results[1])

        const availabilities = cookoonAvailabilities

        Object.entries(availabilities).forEach(([key, value]) => {
          if (value != chefAvailabilities[key]) {
            availabilities[key] = "unavailable"
          }
        })

        return availabilities
      })
    } else if (unavailableDatesForCookoonUrl && !unavailableDatesForChefUrl) {
      typeNames.forEach(typeName => {
        cookoonPromises.push(this.isTypeNameUnavailableForSupplierAndDate(unavailableDatesForCookoonUrl, typeName, date))
      })
      return Promise.all(cookoonPromises).then((results) => {
        return this.fillSupplierAvailabilities(results)
      })
    } else if (!unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
      typeNames.forEach(typeName => {
        chefPromises.push(this.isTypeNameUnavailableForSupplierAndDate(unavailableDatesForChefUrl, typeName, date))
      })
      return Promise.all(chefPromises).then((results) => {
        return this.fillSupplierAvailabilities(results)
      })
    }
  }

  fillSupplierAvailabilities = (results) => {
    const availabilities = {}
    const SupplierAvailabilities = results.map(element => {
      return Object.fromEntries(element)
    })
    SupplierAvailabilities.forEach(element => {
      availabilities[Object.keys(element)[0]] = Object.values(element)[0]
    })
    return availabilities
  }

  isTypeNameUnavailableForSupplierAndDate = (url, typeName, date) => {
    const urlToFetch = `${url}?type_name=${typeName}&start_date_available=${date}&end_date_available=${date}`
    return fetch(urlToFetch)
      .then(response => response.json())
      .then((data) => {
        return [data.datesUnavailable.length].map(element => {
          return element === 0 ? "available" : "unavailable"
        }).map(element => {
          return [typeName, element]
        })
      }).catch(error => {
        // console.log(error)
      })
  }

  disconnect() {
    this.startAtTarget.flatpickr().destroy()
  }

  initSelect2() {
    this.select2Targets.forEach((select2) => {
      $(select2).select2({
        minimumResultsForSearch: -1
      })
    })
  }

  initFlatpickr = (input, startAtTarget, defaultDate, minDate, maxDate, datesUnavailable, clickOpens, startAtDayNameTarget, startAtDayTarget, startAtMonthTarget) => {
    flatpickr(input, {
      disableMobile: "true",
      altInput: true,
      altFormat: "D j F Y",
      dateFormat: 'Y-m-d',
      defaultDate: defaultDate,
      minDate: JSON.parse(minDate),
      maxDate: JSON.parse(maxDate),
      disable: datesUnavailable,
      clickOpens: clickOpens,
      altInputClass : "invisible w-0 p-0",
      wrap: true,
      onValueUpdate: function(selectedDates, dateStr, instance) {
        startAtTarget.value = dateStr
        startAtDayNameTarget.innerText = selectedDates[0].toLocaleDateString('fr-FR', { weekday: 'short' })
        startAtDayTarget.innerText = selectedDates[0].getDate()
        startAtMonthTarget.innerText = selectedDates[0].toLocaleDateString('fr-FR', { month: 'long' })
      },
    })
  }

  unavailableDates = (typeName, peopleCount, startDateAvailable, endDateAvailable, unavailableDatesForCookoonUrl, unavailableDatesForChefUrl) => {
    if (unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
      return Promise.all(
          [
            this.unavailableDatesForSupplier(
              unavailableDatesForCookoonUrl,
              typeName,
              startDateAvailable,
              endDateAvailable
            ),
            this.unavailableDatesForSupplier(
              unavailableDatesForChefUrl,
              typeName,
              startDateAvailable,
              endDateAvailable
            ),
          ]
        ).then((values) => {
          return values[0].concat(values[1])
        })
    } else if (unavailableDatesForCookoonUrl && !unavailableDatesForChefUrl) {
      return this.unavailableDatesForSupplier(
          unavailableDatesForCookoonUrl,
          typeName,
          startDateAvailable,
          endDateAvailable
        ).then((values) => {
          return values
        })
    } else if (!unavailableDatesForCookoonUrl && unavailableDatesForChefUrl) {
      return this.unavailableDatesForSupplier(
          unavailableDatesForChefUrl,
          typeName,
          startDateAvailable,
          endDateAvailable
        ).then((values) => {
          return values
        })
    }
  }

  unavailableDatesForSupplier = (url, typeName, startDateAvailable, endDateAvailable) => {
    const urlToFetch = `${url}?type_name=${typeName}&start_date_available=${startDateAvailable}&end_date_available=${endDateAvailable}`
    return fetch(urlToFetch)
      .then(response => response.json())
      .then((data) => {
        return data.datesUnavailable
      }).catch(error => {
        // console.log(error)
      })
  }

  updatePeopleCount = (cookoonCapacity, cookoonCapacityStanding, chefCapacity) => {
    const peopleCountValue = parseInt(this.peopleCountTarget.value)
    if (["diner_cocktail", "lunch_cocktail"].includes(this.typeNameTarget.value)) { cookoonCapacity = cookoonCapacityStanding }

    $(this.peopleCountTarget).empty()

    const newOptions = Array.from(new Array(Math.min(cookoonCapacity, chefCapacity) - 1), (x, i) => i + 2)
    newOptions.forEach(option => {
      $(this.peopleCountTarget).append(new Option(option, option, false, false))
    })

    // peopleCountValue > chefCapacity impossible
    if (peopleCountValue > cookoonCapacity) {
      this.showCapacityError()
      $(this.peopleCountTarget).val(cookoonCapacity)
      this.capacityErrorTarget.innerText = `Le lieu accepte au plus ${cookoonCapacity} convives en format assis.`
    } else {
      $(this.peopleCountTarget).val(peopleCountValue)
    }

    $(this.peopleCountTarget).trigger('change')
  }

  toggleClass = (element, classOne, classTwo) => {
    if (element.classList.contains(classOne)) {
      element.classList.remove(classOne)
    }
    element.classList.add(classTwo)
  }

  showCapacityError() {
    this.toggleClass(this.capacityErrorTarget, "invisible", "visible")
    this.capacityErrorTarget.classList.add("alert")
    this.capacityErrorTarget.classList.add("alert-danger")
    this.capacityErrorTarget.classList.add("mt-3")
  }

  hideCapacityError() {
    this.toggleClass(this.capacityErrorTarget, "visible", "invisible")
    this.capacityErrorTarget.classList.remove("alert")
    this.capacityErrorTarget.classList.remove("alert-danger")
    this.capacityErrorTarget.classList.remove("mt-3")
  }
}
