import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input'];

  copy(event) {
    const button = event.currentTarget
    navigator.clipboard.writeText(this.inputTarget.value);
    button.classList.contains('btn-cookoon-primary') && button.classList.remove('btn-cookoon-primary')
    !button.classList.contains('btn-cookoon-secondary-gold') && button.classList.add('btn-cookoon-secondary-gold')
    button.innerText = "Copié";
    setTimeout(() => {
      button.classList.contains('btn-cookoon-secondary-gold') && button.classList.remove('btn-cookoon-secondary-gold')
      !button.classList.contains('btn-cookoon-primary') && button.classList.add('btn-cookoon-primary')
      button.innerText = "Copier"
    }, 2500);
  }
}
