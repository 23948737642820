import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['imageUpToTextCard']

  connect() {
    window.addEventListener("load", (event) => {
      const card = this.imageUpToTextCardTarget
      let descriptionSizeAvailable = this.itemHeight(card) - parseInt(getComputedStyle(card)['padding-bottom']) - parseInt(getComputedStyle(card)['padding-top'])
      card.childNodes.forEach(child => {
        if (!child.classList.contains('image_up_to_text_card_description')) {
          descriptionSizeAvailable -= this.itemHeight(child)
        }
      })
      this.setRightNumberOfLines(
        card.querySelector(".image_up_to_text_card_description"),
        descriptionSizeAvailable
      )
    });
  }

  itemHeight = (item) => {
    return (item.offsetHeight + parseInt(getComputedStyle(item)['margin-bottom']) + parseInt(getComputedStyle(item)['margin-top']))
  }

  setRightNumberOfLines = (item, sizeAvailable) => {
    const itemLineHeight = parseInt(getComputedStyle(item)['line-height'])
    const itemLinesAvailable = parseInt(
      sizeAvailable / itemLineHeight
    )
    item.style['-webkit-line-clamp'] = itemLinesAvailable
  }
}
