import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['items', 'item'];

  send(event) {
    event.preventDefault();
    const form = event.currentTarget

    fetch(form.action, {
      method: form.method, // Could be dynamic with Stimulus values
      headers: { "Accept": "application/json" },
      body: new FormData(form)
    })
    .then(response => response.json())
    .then((data) => {
      if (data.insertedItem && this.hasItemsTarget) {
        this.itemsTarget.insertAdjacentHTML("beforeend", data.insertedItem)
        const visible = form.closest('.visible')
        if (visible) {
          visible.classList.remove('visible')
          visible.classList.add('invisible')
          visible.classList.add('d-none')
        }
      }
      if (data.updatedItem && this.hasItemTarget) {
        form.closest("[data-target='insert-in-list.item']").outerHTML = data.updatedItem
      }
      if (data.form) {
        form.outerHTML = data.form
      }
    })
  }
}
