import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dates', 'clear'];

  connect() {
    this.initFlatpickr(
      this.datesTarget,
      this.data.get('mode'),
      this.data.get('minDate'),
      this.data.get('maxDate'),
      JSON.parse(this.data.get('disabledDates'))
    )
  }

  disconnect() {
    // avoid double calendar and wrong date in calendar when click on back browser button
    const date = this.datesTarget.value
    flatpickr(this.datesTarget).destroy()
    this.datesTarget.value = date
  }

  initFlatpickr(dateInput, mode, minDate, maxDate, disabledDates) {
    flatpickr(dateInput, {
      mode: mode,
      altInput: true,
      altFormat: "D j F",
      dateFormat: "Y-m-d",
      minDate: minDate,
      maxDate: maxDate,
      disable: disabledDates,
    });
  }

  clearDates() {
    this.datesTarget.value = ""
    this.initFlatpickr(
      this.datesTarget,
      this.data.get('mode'),
      this.data.get('minDate'),
      this.data.get('maxDate'),
      JSON.parse(this.data.get('disabledDates'))
    )
  }
}
