import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["select2"]

  disconnect() {
    if (this.hasSelect2Target) {
      this.select2Targets.forEach((select2) => {
        $(select2).select2('destroy')
      })
    }
  }

  connect() {
    this.initSelect2()
    $(this.select2Target).on('change', () => {
      this.updateInvitationLimitForm()
    })
  }

  initSelect2() {
    $(this.select2Target).select2({
      dropdownParent: $("#usersModal")
    })
  }

  updateInvitationLimitForm() {
    $('#update-invitation-limit-success').text("")
    if ($(this.select2Target).val()) {
      fetch(`/admin/users/${$(this.select2Target).val()}/edit_invitation_limit.json`)
        .then(response => response.json())
        .then(data => {
          if (data && data.form) {
            document.getElementById("invitation_limit_select_form_container").innerHTML = data.form
          } else {
            console.log('Error')
          }
        })
    } else {
      document.getElementById("invitation_limit_select_form_container").innerHTML = ""
    }
  }
}
