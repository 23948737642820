import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["userForm", "userId", "searchUserEmail", "searchUserForm", "searchUserError", "searchUserSubmit"]

  disconnect() {
  }

  connect() {
    $("#newUserModal").on("hidden.bs.modal", () => this.resetSearchForm());
  }

  resetSearchForm = async () => {
    const currentUserId = await this.retrieveCurrentUserId()
    const user = await this.retrieveUser(currentUserId, "")
    if (user.searchForm) {
      document.querySelector('#newUserModal .modal-body').innerHTML = user.searchForm
    }
  }

  checkIfUserExists = async (event) => {
    event.preventDefault()
    const email = this.searchUserEmailTarget.value
    const currentUserId = await this.retrieveCurrentUserId()
    const user = await this.retrieveUser(currentUserId, email)

    if (user.id) {
      const usersIdsInList = Array.from(this.userIdTarget.querySelectorAll("option")).map((option) => {
       return parseInt(option.value)
      })
      if (usersIdsInList.includes(user.id)) {
        $(this.userIdTarget).val(user.id).trigger('change')
        if (user.searchForm) {
          this.searchUserFormTarget.outerHTML = user.searchForm
          $('#newUserModal').modal('hide')
        }
      } else {
        if (this.searchUserErrorTarget.classList.contains('d-none')) {
          this.searchUserErrorTarget.classList.remove('d-none')
        }
        this.searchUserSubmitTarget.disabled = false
      }
    } else {
      if (user.form) {
        this.searchUserFormTarget.outerHTML = user.form
      }
    }
  }

  createUser(event) {
    event.preventDefault()
    fetch(this.userFormTarget.action, {
      method: this.userFormTarget.method,
      headers: { "Accept": "application/json" },
      body: new FormData(this.userFormTarget)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.user) {
          $('#newUserModal').modal('hide')
          // Set the new user in users list and select it
          const newOption = new Option(`${data.user.email}: ${data.user.firstName} ${data.user.lastName} ${(data.amexMember) ? ` - ${data.amexMember}` : ""}`, data.user.id, false, true);
          $(this.userIdTarget).append(newOption).trigger('change');
          this.removeErrorFromUserIdTarget()
        }
        if (data.searchForm) {
          this.userFormTarget.outerHTML = data.searchForm
        }
        if (data.form) {
          this.userFormTarget.outerHTML = data.form
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  retrieveCurrentUserId = async () => {
    return fetch("/agent/users/set_current_user")
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          return data.id
        } else {
          return "error"
        }
      })
  }

  retrieveUser = async (currentUserId, email) => {
    return fetch(`/agent/users/${currentUserId}?email=${email}`)
      .then(response => response.json())
      .then(data => {
        if (data.user) {
          return data.user
        } else {
          return "error"
        }
      })
  }

  removeErrorFromUserIdTarget() {
    if (this.userIdTarget.parentElement.classList.contains("form-group-invalid")) {
      this.userIdTarget.parentElement.classList.remove("form-group-invalid")
    }
    if (this.userIdTarget.parentElement.querySelector(".invalid-feedback")) {
      this.userIdTarget.parentElement.querySelector(".invalid-feedback").remove()
    }
  }
}
